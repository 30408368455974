
.http-error-page {
	padding: 10px;
	h1, h2, h3, h4 {
		text-align: center;
		margin: 10px 0;
		max-width: none;
		width: auto;
	}
}

// Footer
#ad-footer {
	clear: both;
	margin: 20px 0;
	text-align: center;
	a {
		display: inline-block;
	}
	img,
	video {
		max-width: 100%;
	}
	video {
		vertical-align: middle;
	}
	@media (max-width: 768px) {
		img,
		video {
			width: 100%;
		}
	}
}

#language-switcher {
	position: absolute;
	right: 10px;
	top: 0;
}

#social-links {
	clear: both;
	margin: @social-links-margin;
	text-align: center;
	overflow: hidden;
	> a {
		vertical-align: text-bottom;
	}
	> div {
		display: inline-block;
		vertical-align: middle;
	}
}

.bottom-msg {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: 13px;
	line-height: 20px;
	text-align: center;
	.body {
		background: #000;
		background: fadeout(#000, 10%);
		color: #fff;
		padding: 8px 20px;
	}
	.close {
		float: right;
		background: #fff;
		font-size: 16px;
		font-weight: bold;
		background: #fff;
		color: #000;
		border-radius: 50%;
		height: 20px;
		width: 20px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
	}
	a {
		color: #fff;
		text-decoration: underline;
	}
	@media (max-width: @screen-xs) {
		.body {
			padding: 5px 10px;
			font-size: 12px;
		}
	}
}