
@import "../../bootstrap/variables.less";

@body-bg:					#000;

@text-color:				#fff;
@link-color:				#ffcc00;
@link-hover-color:			#ffcc00;
@link-hover-decoration:		underline;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			18px;
@line-height-base:			1.25;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				492px;
@thumb-height:				371px;
@thumb-margin-top:			1px;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		1px;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #323630;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#000;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@parent-alert-padding:		10px;
@parent-alert-margin:		20px auto;
@parent-alert-border:		1px solid #fff;
@parent-alert-font-size:	11px;
@parent-alert-color:		#fff;
@parent-alert-bg:			transparent;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
}
h1 {
	background-color: #f8e61c;
	margin: 0 0 3px;
	font-size: 18px;
	.title {
		color: #fff;
		font-weight: bold;
		padding: 3px 5px;
	}
}
#language-switcher {
	float: right;
	margin: 0 10px;
	width: 50px;
	color: #000;
	background: #f8e61c;
	border-radius: 100px;
	text-align: center;
}