
.x-popup {
	position: absolute;
	z-index: @zindex-popup;
	padding: @tooltip-arrow-width 0;
	.x-popup-content {
		background: @tooltip-bg;
		padding: 0;
		overflow-x: hidden;
		overflow-y: auto;
		zoom: 1;
		color: @popup-text-color;
		font-weight: @popup-font-weight;
		a {
			color: @popup-text-color;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				padding: 10px 20px;
				&:hover {
					background: darken(@tooltip-bg, 5%);
				}
				a {
					text-decoration: none;
					display:block;
				}
			}
		}
	}
	.x-popup-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.x-popup-close {
		cursor: pointer;
		position: absolute;
		right: 5px;
		top: 7px;
		color: @tooltip-color;
		font-weight: bold;
		font-size: 13px;
		line-height: 15px;
		font-family: 'sans-serif';
	}
	&.above {
		margin-bottom: -@tooltip-arrow-width;
		.x-popup-arrow {
			bottom: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
			border-top-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 10px;
		}
	}
	&.below {
		margin-top: -@tooltip-arrow-width;
		.x-popup-arrow {
			top: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
			border-bottom-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 10px;
		}
	}
}