
#parent-alert {
	max-width: 800px;
	padding: @parent-alert-padding;
	margin: @parent-alert-margin;
	border: @parent-alert-border;
	text-align: left;
	font-size: @parent-alert-font-size;
	color: @parent-alert-color;
	background: @parent-alert-bg;
}