
.mozaique {
    overflow: hidden;
	text-align: center;
}

.thumb-simple {
	.inline-block;
	vertical-align: top;
	padding: @thumb-margin-top @thumb-margin-right @thumb-margin-bottom @thumb-margin-left;
	width: @thumb-width + @thumb-margin-right + @thumb-margin-left + (2 * @thumb-border-width);
	height: @thumb-height + @thumb-margin-top + @thumb-margin-bottom + (2 * @thumb-border-width);
	a {
		border: @thumb-border;
		display: block;
		width: @thumb-width + (2 * @thumb-border-width);
		height: @thumb-height + (2 * @thumb-border-width);
		position: relative;
		overflow: hidden;
		zoom: 1;
		&:hover {
			border: @thumb-hover-border
		}
	}
	img {
		width: 100%;
		&.hovered {
			box-sizing: content-box;
			position: absolute;
			width: 300%;
			height: 300%;
			top: 0;
			left: 0;
			margin-left: 0%;
			margin-top: 0%;
		}
	}
}

@media(max-width: (@thumb-width + @thumb-margin-right + @thumb-margin-left)) {
	.thumb-simple {
		width: 100%;
		height: auto;
	}
}